<template>
  <div>
  	<b-card
      no-body
      class="border p-2"
    >
	    <!-- Alert: No item found -->
	    <b-alert
	      variant="danger"
	      :show="packageData === undefined"
	    >
	      <h4 class="alert-heading">
	        Error fetching package data
	      </h4>
	      <div class="alert-body">
	        No Package found with this id. Check
	        <b-link
	          class="alert-link"
	          :to="{ name: 'package-list'}"
	        >
	          Package List
	        </b-link>
	        for other packages.
	      </div>
	    </b-alert>

	    <template >

	      <b-row>
	        <b-col cols="12" >
	          <b-card>
					    <b-row v-if="packageData">
					    	<b-col cols="12" xl="6" >
					        <table class="mt-2 mt-xl-0 w-100">
					          <tr>
					            <th class="pb-50">
					              <span class="font-weight-bold">Package Name</span>
					            </th>
					            <td class="pb-50 text-capitalize">
					              {{ packageData.name }}
					            </td>
					          </tr>
					          <tr>
					            <th class="pb-50">
					              <span class="font-weight-bold">Package Plan</span>
					            </th>
					            <td class="pb-50 text-capitalize">
					              ${{ packageData.price }} <sub v-if="packageData.duration">/ {{ packageData.duration }}</sub>
					            </td>
					          </tr>
					          <tr>
					            <th class="pb-50">
					              <span class="font-weight-bold">Status</span>
					            </th>
					            <td class="pb-50 text-capitalize">
					              <b-badge v-if="packageData.is_archived"
					                pill
					                variant="light-danger"
					                class="text-capitalize"
					              >
					              	Archived
					              </b-badge>
					              <b-badge v-else-if="!packageData.is_archived"
					                pill
					                variant="light-success"
					                class="text-capitalize"
					              >
					                Active
					              </b-badge>
					            </td>
					          </tr>
					        </table>
					      </b-col>

					      <b-col class="mt-1" cols="12" xl="12" >
						      <span class="font-weight-bold">Modules:</span>
						      
	                <b-form-group>
	                  <b-form-checkbox-group
	                    id="checkbox-group-1"
	                    v-model="module_ids"
	                    :options="modulesData"
	                    value-field="id"
	                    text-field="name"
	                    name="flavour-1"
	                    class="demo-inline-spacing"
	                    disabled
	                  />
	                </b-form-group>
		              
					    	</b-col>

			          <!-- Edit -->
			          <b-col cols="12" class="mt-2">
			            <div class="d-flex align-items-start justify-content-start">
			              <b-button
			                 v-if="$can('edit', 'Package')"
			                variant="primary"
			                :to="{ name: 'package-edit'}"
			                class="btn-sm"
			              >
			              	<feather-icon icon="EditIcon" />
			                <span class="align-middle ml-50">Edit</span>
			              </b-button>
			            </div>
			          </b-col>
					    </b-row>
					  </b-card>
	        </b-col>
	      </b-row>

	    </template>
	</b-card>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BFormGroup, BFormCheckboxGroup, BButton, BBadge,
} from 'bootstrap-vue'
import packageStoreModule from '../packageStoreModule'

export default {
  components: {
    BRow, BCol, BAlert, BLink,
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BFormGroup, BFormCheckboxGroup, BButton, BBadge,
  },
  setup() {
    const packageData = ref(null)
    const module_ids = ref(null)
    const modulesData = ref(null)

    const PACKAGE_APP_STORE_MODULE_NAME = 'app-package'

    // Register module
    if (!store.hasModule(PACKAGE_APP_STORE_MODULE_NAME)) store.registerModule(PACKAGE_APP_STORE_MODULE_NAME, packageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PACKAGE_APP_STORE_MODULE_NAME)) store.unregisterModule(PACKAGE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-package/fetchData', { id: router.currentRoute.params.id })
      .then((response) => { 
          packageData.value = response.data.data 
          module_ids.value = response.data.module_ids
      })
      .catch(error => {
        packageData.value = undefined
      })

    store.dispatch('app-package/fetchModuleOption')
        .then((response) => {
          if(response.data.status == 1)
            modulesData.value = response.data.data
      })

    return {
      packageData,
      module_ids,
      modulesData
    }
  },
}
</script>

<style>
	.demo-inline-spacing > * {
    margin-top: 1rem !important;
	}
</style>
